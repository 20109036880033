import { FC } from "react";
import { SectionCard } from "./SectionCard";
import SportsCoverBg from "../../../../assets/oropuroV2/section_covers/sports_cover_bg.webp"
import SportCover from "../../../../assets/salta-la-banca/section_covers/sport_cover.webp";
import SportCover2x from "../../../../assets/salta-la-banca/section_covers/sport_cover@2x.webp";
import SportCover3x from "../../../../assets/salta-la-banca/section_covers/sport_cover@3x.webp";
import SlotsCover from "../../../../assets/salta-la-banca/section_covers/slots_cover.webp";
import SlotsCover2x from "../../../../assets/salta-la-banca/section_covers/slots_cover@2x.webp";
import SlotsCover3x from "../../../../assets/salta-la-banca/section_covers/slots_cover@3x.webp";
import SlotsCoverBg from "../../../../assets/salta-la-banca/section_covers/halloween_pack/slots_cover_bg.webp";
import CrashBg from "../../../../assets/salta-la-banca/section_covers/crash_bg.webp";
import CrashCover from "../../../../assets/salta-la-banca/section_covers/crash_cover.webp";
import CrashCover2x from "../../../../assets/salta-la-banca/section_covers/crash_cover@2x.webp";
import CrashCover3x from "../../../../assets/salta-la-banca/section_covers/crash_cover@3x.webp";
import LiveCasinoBg from "../../../../assets/salta-la-banca/section_covers/live_casino_bg.webp"
import LiveCasinoCover from "../../../../assets/salta-la-banca/section_covers/live_casino_cover.webp";
import LiveCasinoCover2x from "../../../../assets/salta-la-banca/section_covers/live_casino_cover@2x.webp";
import LiveCasinoCover3x from "../../../../assets/salta-la-banca/section_covers/live_casino_cover@3x.webp";
import TableGamesBg from "../../../../assets/oropuroV2/section_covers/table_games_cover_bg.webp"
import TableGamesCover3x from "../../../../assets/salta-la-banca/section_covers/table_games_cover@3x.webp";
import TableGamesCover from "../../../../assets/salta-la-banca/section_covers/table_games_cover.webp";
import TableGamesCover2x from "../../../../assets/salta-la-banca/section_covers/table_games_cover@2x.webp";
import Stack from "@mui/material/Stack";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const GameSections: FC = () => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang, categoriesDescription: catDescLang}}}} = useLanguage()
    const { largeScreen  } = useCommonMediaQueries()

    return <Stack
        direction={'row'}
        width={'100%'}
        gap={largeScreen ? '10px' : '0.5vw'}
        px={'8px'}
        pb={'8px'}
        sx={{
            overflowX: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#15181D #15181D10',
        }}
    >
        <SectionCard
            backgroundImgUrl={CrashBg}
            imageUrl={{
                small: CrashCover,
                medium: CrashCover2x,
                large: CrashCover3x
            }}
            title={menuLang.crash}
            description={catDescLang.crash}
            url={'/crash'}
        />
        <SectionCard
            backgroundImgUrl={SlotsCoverBg}
            imageUrl={{
                small: SlotsCover,
                medium: SlotsCover2x,
                large: SlotsCover3x
            }}
            title={menuLang.slots}
            description={catDescLang.slots}
            url={'/slots'}
        />
        <SectionCard
            backgroundImgUrl={LiveCasinoBg}
            imageUrl={{
                small: LiveCasinoCover,
                medium: LiveCasinoCover2x,
                large: LiveCasinoCover3x
            }}
            title={menuLang.liveCasino}
            description={catDescLang.liveCasino}
            url={'/live_casino'}
        />

        <SectionCard
            backgroundImgUrl={SportsCoverBg}
            imageUrl={{
                small: SportCover,
                medium: SportCover2x,
                large: SportCover3x
            }}
            title={menuLang.sport}
            description={catDescLang.sport}
            url={'/sport'}
        />
        <SectionCard
            backgroundImgUrl={TableGamesBg}
            imageUrl={{
                small: TableGamesCover,
                medium: TableGamesCover2x,
                large: TableGamesCover3x
            }}
            title={menuLang.tableGames}
            description={catDescLang.tableGames}
            url={'/table_games'}
        />
    </Stack>
}